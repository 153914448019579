import { render, staticRenderFns } from "./_Whitelist.vue?vue&type=template&id=6804b7af"
import script from "./_Whitelist.vue?vue&type=script&lang=js"
export * from "./_Whitelist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports