<template>
  <div>
    <b-row>
      <b-col md="24">
        <b-form-group
          label="Vertegenwoordiger"
          :disabled="
            auth.role.id !== $root.Role.ADMIN &&
            auth.role.id !== $root.Role.AGENT
          "
        >
          <b-form-select v-model="customer.agent_id">
            <b-select-option :value="null" selected> Geen </b-select-option>

            <b-select-option
              v-for="agent of agents"
              :key="agent.id"
              :value="agent.id"
            >
              {{ agent.formatted_name }}
            </b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="24">
        <Input
          :disabled="auth.role_id !== $root.Role.ADMIN"
          v-model="customer.commission_percentage"
          label="Commissie"
          :min="0"
          type="number"
          class="mb-0"
          append="%"
        />
      </b-col>

      <b-col md="24">
        <b-form-group
          label="Soort klant"
          :disabled="
            auth.role.id !== $root.Role.ADMIN &&
            auth.role.id !== $root.Role.AGENT
          "
        >
          <b-form-select v-model="customer.type">
            <b-form-select-option value="dealer">Dealer</b-form-select-option>
            <b-form-select-option value="customer">
              Reguliere klant
            </b-form-select-option>
            <b-form-select-option value="gadero">
              Gadero klant
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="24" v-if="customer.type === 'dealer'">
        <b-form-group
          label="Soort dealer"
          :disabled="
            auth.role.id !== $root.Role.ADMIN &&
            auth.role.id !== $root.Role.AGENT
          "
        >
          <b-form-select v-model="customer.dealer_type">
            <b-form-select-option :value="null" disabled>
              Kies een soort dealer
            </b-form-select-option>
            <b-form-select-option value="dealer_2">
              {{ $root.CustomerTypeString['dealer_2'] }}
            </b-form-select-option>
            <b-form-select-option value="dealer_1">
              {{ $root.CustomerTypeString['dealer_1'] }}
            </b-form-select-option>
            <b-form-select-option value="dealer_0">
              {{ $root.CustomerTypeString['dealer_0'] }}
            </b-form-select-option>
            <b-form-select-option value="dealer_star">
              {{ $root.CustomerTypeString['dealer_star'] }}
            </b-form-select-option>
            <b-form-select-option value="dealer_project">
              {{ $root.CustomerTypeString['dealer_project'] }}
            </b-form-select-option>
            <b-form-select-option value="dealer_inactive">
              {{ $root.CustomerTypeString['dealer_inactive'] }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editor: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editor_content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      agents: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: 'auth/user',
    }),
  },
  methods: {
    getAgents() {
      this.loading = true;

      let params = {
        agents: true,
      };

      this.$http
        .get('users', { params: params })
        .then((response) => {
          this.agents = response.data.data;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAgents();
  },
};
</script>
