<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-card header="Creditfactuur">
          <b-row class="mb-3">
            <b-col>
              <h3>
                {{ creditNote.customer.billing_formatted_name }}
              </h3>
              <p v-if="creditNote.customer.billing_company">
                {{ creditNote.customer.billing_company }}
              </p>
              <p>
                {{ creditNote.customer.billing_address }}
              </p>
              <p v-if="creditNote.customer.vat_number" class="mt-2">
                BTW NR {{ creditNote.customer.vat_number }}
              </p>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="float-right mb-3"
            v-b-modal.add-orderline
            v-if="creditNote.status === CreditStatus.OPEN"
          >
            <i class="far fa-stream" />Regel toevoegen
          </b-button>

          <b-table
            striped
            foot-clone
            hover
            show-empty
            responsive
            class="table-nowrap"
            :items="creditNote.lines"
            :fields="fields"
          >
            <template #empty>Er zijn nog geen regels toegevoegd</template>

            <template #cell(name)="row">
              <template v-if="row.item.product">
                <span class="column-sku">{{ row.item.product.sku }}</span>
                {{ row.item.product.name }}
              </template>
              <template v-else>{{ row.item.name }}</template>
            </template>

            <template #cell(quantity)="row">
              <template v-if="creditNote.status === CreditStatus.OPEN">
                <Input
                  type="number"
                  min="0"
                  v-model.number="row.item.quantity"
                />
              </template>
              <template v-else>
                {{ row.item.quantity }}
              </template>
            </template>

            <template #head(update_stock)="">
              <template v-if="creditNote.status === CreditStatus.OPEN">
                <span
                  v-b-tooltip.right.hover
                  title="Geef per product aan of de voorraad opgeboekt moet worden"
                >
                  <i class="far fa-info-circle" />
                </span>
              </template>
              <template v-else>Voorraad opgeboekt</template>
            </template>

            <template #cell(update_stock)="row">
              <template v-if="row.item.product">
                <template v-if="creditNote.status === CreditStatus.OPEN">
                  <b-form-checkbox v-model="row.item.update_stock" switch />
                </template>
                <template v-else>
                  {{ row.item.update_stock ? 'Ja' : 'Nee' }}
                </template>
              </template>
            </template>

            <template #cell(price)="row">
              <template v-if="creditNote.status === CreditStatus.OPEN">
                <Input
                  prepend="€"
                  type="number"
                  step="0.01"
                  v-model.number="row.item.price"
                />
              </template>
              <template v-else>
                &euro;
                {{ row.item.price | formatNumber(2) }}
              </template>
            </template>

            <template #cell(total)="row">
              &euro;
              {{ (row.item.quantity * row.item.price) | formatNumber(2) }}
            </template>

            <template #cell(actions)="row">
              <b-button
                v-if="creditNote.status === CreditStatus.OPEN"
                variant="outline-primary"
                v-b-tooltip.hover.left="`Deze orderregel verwijderen?`"
                @click="deleteCreditLine(row)"
              >
                <i class="far fa-trash-alt mr-0"></i>
              </b-button>
            </template>
          </b-table>

          <b-row class="d-flex justify-content-between">
            <b-col md="20">
              <strong class="d-block">Opmerking:</strong>
              <b-form-textarea
                id="textarea"
                class="mt-2"
                placeholder="Eventuele opmerkingen.."
                rows="3"
                max-rows="6"
                v-model="creditNote.comments"
                v-if="creditNote.status === CreditStatus.OPEN"
              />
              <template v-else>
                {{ creditNote.comments }}
              </template>
            </b-col>
            <b-col md="16">
              <b-list-group class="lines">
                <b-list-group-item class="d-flex justify-content-between">
                  <b>Subtotaal ex:</b>
                  <span v-if="price.subtotal">
                    &euro;
                    {{ price.subtotal | formatNumber(2) }}
                  </span>
                  <span v-else>
                    &euro;
                    {{ price.subtotal | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.shippingCosts"
                >
                  <b>Verzendkosten:</b>
                  <span>
                    &euro;
                    {{ price.shippingCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.extraCosts"
                >
                  <b>Extra kosten:</b>
                  <span>
                    &euro;
                    {{ price.extraCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.discount"
                >
                  <b>Korting:</b>
                  <span>
                    &euro;
                    {{ price.discount | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>BTW:</b>
                  <span>
                    &euro;
                    {{ price.tax | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>Totaal incl:</b>
                  <span>
                    &euro;
                    {{ price.total | formatNumber(2) }}
                  </span>
                </b-list-group-item>
              </b-list-group>

              <b-list-group class="lines mt-2">
                <b-list-group-item class="d-flex justify-content-between">
                  <b>Inkoopwaarde voorraad</b>
                  <span>
                    &euro;
                    {{ price.purchaseTotal | formatNumber(2) }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Acties">
          <span id="disabled-wrapper" class="d-block" tabindex="0">
            <b-button
              block
              variant="primary"
              :disabled="saveDisabled"
              @click="sendCreditMail"
              v-if="auth.role.id === $root.Role.ADMIN"
            >
              Credit versturen
            </b-button>
          </span>
          <b-tooltip target="disabled-wrapper">
            <template v-if="creditNote.lines.length === 0">
              Voeg minimaal één credit regel toe
            </template>
          </b-tooltip>

          <b-button
            :class="{ 'mt-2': auth.role.id === $root.Role.ADMIN }"
            variant="secondary"
            @click="updateCredit()"
            block
            v-if="creditNote.status === CreditStatus.OPEN"
          >
            Wijzigingen opslaan
          </b-button>

          <hr v-if="auth.role.id === $root.Role.ADMIN" />

          <b-button
            variant="secondary"
            block
            :to="{
              name: 'customer.show',
              params: {
                id: creditNote.customer.id,
              },
            }"
            target="_blank"
          >
            Klant bekijken
          </b-button>

          <template v-if="auth.role.id === $root.Role.ADMIN">
            <hr />

            <b-button @click="deleteCredit" variant="danger" block>
              Credit verwijderen
            </b-button>
          </template>
        </b-card>

        <b-card class="mt-4" header="Informatie">
          <b-row>
            <b-col>
              <p><b>Creditnummer</b></p>
              <template v-if="creditNote.number">
                {{ creditNote.number }}
              </template>
              <template v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </template>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Creditdatum</b></p>
              <template v-if="creditNote.date">
                {{ new Date(creditNote.date) | date }}
              </template>
              <template v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </template>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Aangemaakt door</b></p>
              {{ creditNote.created_by.formatted_name }}
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Aangemaakt op</b></p>
              {{ new Date(creditNote.created_at) | date }}
            </b-col>
          </b-row>

          <hr />

          <b-row class="mt-3">
            <b-col>
              <p>
                <b>Exact</b>
                <i
                  class="fas ml-2"
                  :class="{
                    'fa-exclamation-triangle text-warning':
                      !creditNote.exact_id,
                    'fa-check-circle text-success': creditNote.exact_id,
                  }"
                />
              </p>
              <p v-if="!creditNote.exact_id">
                Nog niet ingeboekt, dit gebeurt automatisch na het versturen van
                de creditfactuur.
              </p>
              <p v-else>De creditfactuur is netjes ingeboekt in Exact.</p>

              <b-button
                v-if="
                  !creditNote.exact_id &&
                  creditNote.status === 2 &&
                  auth.role.id === $root.Role.ADMIN
                "
                @click="createExactSalesEntry"
                variant="primary"
                class="mt-2"
                block
              >
                Inboeken in Exact
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mt-4" header="Kosten">
          <template v-if="creditNote.status === CreditStatus.OPEN">
            <Input
              prepend="€"
              v-model.number="creditNote.shipping_costs"
              label="Verzendkosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p>
              <b>Verzendkosten</b>
            </p>
            <p>
              &euro;
              {{
                creditNote.shipping_costs
                  ? creditNote.shipping_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="creditNote.status === CreditStatus.OPEN">
            <Input
              prepend="€"
              v-model.number="creditNote.extra_costs"
              label="Extra kosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Extra kosten</b>
            </p>
            <p>
              &euro;
              {{
                creditNote.extra_costs
                  ? creditNote.extra_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="creditNote.status === CreditStatus.OPEN">
            <b-form-group label="Korting">
              <b-select v-model="creditNote.discount_type">
                <b-select-option disabled :value="null">
                  Kies een optie
                </b-select-option>
                <b-select-option value="percentage">Percentage</b-select-option>
                <b-select-option value="price">Bedrag</b-select-option>
              </b-select>
            </b-form-group>
            <Input
              v-model.number="creditNote.discount"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Korting</b>
            </p>
            <p>
              <template
                v-if="
                  creditNote.discount_type === 'price' ||
                  !creditNote.discount_type
                "
              >
                &euro;
                {{
                  creditNote.discount
                    ? creditNote.discount
                    : 0 | formatNumber(2)
                }}
              </template>
              <template v-else>
                {{
                  creditNote.discount
                    ? creditNote.discount
                    : 0 | formatNumber(2)
                }}
                %</template
              >
            </p>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="add-orderline"
      centered
      hide-footer
      size="lg"
      title="Regel toevoegen"
    >
      <b-row>
        <b-col>
          <search-select
            searchable
            @input="selectProduct"
            searchType="products"
          />
        </b-col>
      </b-row>

      <template v-if="selectedProduct">
        <b-jumbotron class="mt-3 jumbotron--small">
          <h5>
            <b>{{ selectedProduct.sku }}</b> toevoegen aan de creditfactuur
          </h5>

          <p>
            <i>{{ selectedProduct.name }}</i>
          </p>

          <Input
            class="mt-2"
            type="number"
            min="0"
            placeholder="Aantal"
            label="Aantal"
            v-model="newCreditLine.quantity"
          />
          <b-button variant="primary" @click="addCreditLine">
            Voeg regel toe
          </b-button>
        </b-jumbotron>
      </template>

      <span class="or-seperator">Of, voer een vrije regel in</span>

      <b-jumbotron class="mt-3 jumbotron--small">
        <Input placeholder="Naam" label="Naam" v-model="freeCreditLine.name" />
        <b-row>
          <b-col md="24">
            <Input
              placeholder="Aantal"
              label="Aantal"
              type="number"
              min="0"
              v-model="freeCreditLine.quantity"
            />
          </b-col>
          <b-col>
            <Input
              placeholder="Prijs"
              label="Prijs"
              step="0.01"
              prepend="€"
              type="number"
              v-model="freeCreditLine.price"
            />
          </b-col>
        </b-row>
        <b-button variant="primary" @click="addFreeCreditLine">
          Voeg vrije regel toe
        </b-button>
      </b-jumbotron>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import BackButton from '@/components/BackButton';

import SearchSelect from '@/components/SearchSelect';
import { CreditStatus } from '@/enums/CreditNote/Status';

export default {
  components: { BackButton, SearchSelect },

  data() {
    return {
      CreditStatus: CreditStatus,

      creditNote: {
        customer: {},
        created_by: {},
        created_at: null,
        lines: [],
      },

      companyInfo: {},

      products: [],

      selectedProduct: null,

      freeCreditLine: {},
      newCreditLine: {
        quantity: 1,
      },

      fields: [
        { key: 'name', label: 'Naam', tdClass: 'column-wrap-content' },
        {
          key: 'quantity',
          label: 'Aantal',
          tdClass: 'column-form-control-max-width',
        },
        { key: 'update_stock', label: 'Voorraad bijwerken' },
        { key: 'price', label: 'Prijs', tdClass: 'column-form-control' },
        { key: 'total', label: 'Totaal' },
        { key: 'actions', label: '' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      auth: 'auth/user',
    }),

    price() {
      let total = 0;
      let subtotal = 0;
      let subtotal$ = 0;
      let shippingCosts = this.creditNote.shipping_costs ?? 0;
      let extraCosts = this.creditNote.extra_costs ?? 0;
      let discount = this.creditNote.discount ?? 0;
      let discount_type = this.creditNote.discount_type ?? 0;
      let tax = 0;
      let purchaseTotal = 0;

      this.creditNote.lines.forEach((line) => {
        subtotal += line.quantity * line.price;

        purchaseTotal += line.update_stock
          ? line.quantity * line.product.purchase_price
          : 0;
      });

      subtotal$ = subtotal + shippingCosts + extraCosts;

      if (discount) {
        discount =
          discount_type === 'percentage'
            ? subtotal$ * -(discount / 100)
            : discount;

        subtotal$ = subtotal$ - discount;
      }

      if (this.creditNote.customer.is_foreign_customer) {
        tax = 0;
      } else {
        let vatAmount = 0.21;

        switch (this.creditNote.customer.country) {
          case 'de':
            vatAmount = 0.19;
            break;

          case 'nl':
          default:
            vatAmount = 0.21;
            break;
        }

        tax = subtotal$ * vatAmount;
      }

      total = subtotal$ + tax;

      return {
        total: total,
        subtotal: subtotal,
        shippingCosts: shippingCosts,
        extraCosts: extraCosts,
        discount: discount,
        tax: tax,
        purchaseTotal: purchaseTotal,
      };
    },

    saveDisabled() {
      return this.creditNote.lines.length === 0;
    },
  },

  methods: {
    selectProduct(e) {
      this.selectedProduct = e;
    },

    sendCreditMail() {
      this.$root.showConfirm(
        'Weet je zeker dat je deze creditfactuur wil versturen?',
        () => {
          this.$http
            .get(`create-credit-note/${this.creditNote.id}`)
            .then(() => {
              this.$root.notify('De credit is succesvol verstuurd.');

              this.getCredit();
            });
        }
      );
    },

    addCreditLine() {
      let data = {
        ...this.newCreditLine,
        ...{
          sku: this.selectedProduct.sku,
          product: {
            sku: this.selectedProduct.sku,
            name: this.selectedProduct.name,
          },
          product_id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          price: this.selectedProduct.price * -1,
        },
      };

      this.creditNote.lines.push(data);

      this.$root.notify('De orderregel is succesvol aangemaakt.');

      this.$bvModal.hide('add-orderline');
      this.selectedProduct = null;
      this.newCreditLine = {
        quantity: 1,
      };
    },

    addFreeCreditLine() {
      let data = {
        sku: '-',
        name: this.freeCreditLine.name,
        price: this.freeCreditLine.price,
        quantity: this.freeCreditLine.quantity,
      };

      this.creditNote.lines.push(data);

      this.$root.notify('De orderregel is succesvol aangemaakt.');

      this.$bvModal.hide('add-orderline');
      this.freeCreditLine = {};
    },

    deleteCreditLine(row) {
      this.creditNote.lines.splice(row.index, 1);
    },

    downloadCreditNote() {
      this.$http
        .get(`credit-notes/${this.creditNote.id}/download`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Creditfactuur ${this.creditNote.number} - Floer BV`;
          link.click();
        });
    },

    getCredit() {
      this.$http
        .get(`credit-notes/${this.$route.params.id}`)
        .then((response) => {
          this.creditNote = response.data.data;
        });
    },

    deleteCredit() {
      this.$root.showConfirm(
        'Weet je zeker dat je deze creditfactuur wil verwijderen?',
        () => {
          this.$http.delete(`credit-notes/${this.creditNote.id}`).then(() => {
            this.$root.notify('De credit is succesvol verwijderd.');
            this.$router.push({ name: 'invoice.index', query: { tab: 1 } });
          });
        }
      );
    },

    updateCredit() {
      this.$http.put(`credit-notes/${this.creditNote.id}`, this.creditNote);
    },

    createExactSalesEntry() {
      let params = {
        id: this.creditNote.id,
        type: 'credit',
      };

      this.$http
        .get(`exact/create-sales-entry`, { params: params })
        .then(() => {
          this.$root.notify('De creditfactuur is succesvol ingeboekt.');
        })
        .catch((errors) => {
          this.$root.notify(errors.data.message, 'danger');
        });
    },
  },

  mounted() {
    this.getCredit();
  },
};
</script>
