<template>
  <div>
    <b-row>
      <b-col>
        <b-table-simple class="table-order-info">
          <b-tbody>
            <b-tr>
              <b-th>
                {{ OrderChannelString[order.channel] }}
                ordernummer
              </b-th>
              <b-td colspan="2">
                <span>
                  {{ order.order_number }}
                </span>
              </b-td>
            </b-tr>

            <b-tr class="table-spacing-top">
              <b-th>Klantnaam</b-th>
              <b-td colspan="2" class="column-input">
                <Input v-model="order.delivery_name" />
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Bezorgadres</b-th>
              <b-td colspan="2" class="column-input">
                <Input v-model="order.delivery_address" />
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Land van bezorging</b-th>
              <b-td colspan="2" class="column-input">
                <b-form-select v-model="order.delivery_country">
                  <b-form-select-option value="NL">
                    🇳🇱 Nederland
                  </b-form-select-option>
                  <b-form-select-option value="BE">
                    🇧🇪 België
                  </b-form-select-option>
                  <b-form-select-option value="DE">
                    🇩🇪 Duitsland
                  </b-form-select-option>
                  <b-form-select-option value="FR">
                    🇫🇷 Frankrijk
                  </b-form-select-option>
                  <b-form-select-option value="CZ">
                    🇨🇿 Tsjechië
                  </b-form-select-option>
                  <b-form-select-option value="ES">
                    🇪🇸 Spanje
                  </b-form-select-option>
                </b-form-select>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Levering</b-th>
              <b-td colspan="2" width="1" class="column-input">
                <b-select v-model="order.delivery_type">
                  <b-select-option :value="1">Op datum</b-select-option>
                  <b-select-option :value="2">Op afroep</b-select-option>
                  <b-select-option :value="3">Laten afhalen</b-select-option>
                  <b-select-option :value="4">Eigen bus</b-select-option>
                </b-select>

                <vc-date-picker
                  is-expanded
                  title-position="left"
                  show-iso-weeknumbers
                  :attributes="calAttrs"
                  mode="date"
                  :available-dates="[
                    { start: new Date(), end: null },
                    order.delivery_date,
                  ]"
                  :disabled-dates="[
                    { weekdays: [1, 7] },
                    { start: null, end: new Date() },
                  ]"
                  locale="nl"
                  trim-weeks
                  v-model="order.delivery_date"
                  v-if="
                    order.delivery_type === 1 ||
                    order.delivery_type === 3 ||
                    order.delivery_type === 4
                  "
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-input-group class="mt-2">
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fad fa-calendar" />
                        </b-input-group-text>
                      </template>
                      <b-input :value="inputValue" v-on="inputEvents" />
                    </b-input-group>
                  </template>
                </vc-date-picker>

                <b-alert
                  class="mt-2"
                  :variant="withinRange ? 'warning' : 'primary'"
                  :show="
                    (order.distance_as_the_crow_flies &&
                      order.status === OrderStatus.ON_HOLD) ||
                    order.status === OrderStatus.PROCESSING
                  "
                >
                  <p class="mb-2">
                    <span v-if="withinRange">
                      <i class="fas fa-circle-exclamation text-warning mr-1" />
                    </span>
                    <i>
                      Wanneer de afstand minder is dan 30 kilometer kan de
                      levering met eigen bus worden gedaan.
                    </i>
                  </p>
                  <p>
                    <b>Afstand hemelsbreed</b>
                    {{
                      (order.distance_as_the_crow_flies / 1000)
                        | formatNumber(2)
                    }}
                    kilometer
                  </p>
                </b-alert>
              </b-td>
            </b-tr>

            <b-tr class="table-spacing-top">
              <b-th>Status</b-th>
              <b-td
                colspan="2"
                :class="{ 'column-input': user.role.id === $root.Role.ADMIN }"
              >
                <template v-if="user.role.id === $root.Role.ADMIN">
                  <b-form-select v-model="order.status">
                    <b-form-select-option
                      v-for="(value, index) in OrderStatus"
                      :key="index"
                      :value="value"
                      >{{ OrderStatusString[value] }}
                    </b-form-select-option>
                  </b-form-select>
                </template>
                <template v-else>
                  {{ OrderStatusString[order.status] }}
                </template>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Aangemaakt op</b-th>
              <b-td colspan="2">
                <span>
                  {{ new Date(order.created_at) | date }}
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { OrderStatus, OrderStatusString } from '@/enums/Order/Status';
import { OrderChannelString } from '@/enums/Order/Channel';

export default {
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      },
    },

    user: {
      type: Object,
    },
  },

  computed: {
    withinRange() {
      return this.order.distance_as_the_crow_flies / 1000 <= 30;
    },
  },

  data() {
    return {
      OrderStatus: OrderStatus,
      OrderStatusString: OrderStatusString,
      OrderChannelString: OrderChannelString,

      calAttrs: [
        {
          key: 'today',
          highlight: {
            color: 'blue',
            fillMode: 'light',
          },
          dates: new Date(),
        },
      ],
    };
  },

  methods: {
    updateValue(value) {
      if (value === '') {
        value = null;
      }

      this.$emit('input', value);
    },
  },
};
</script>
